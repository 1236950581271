<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin tài khoản kế toán'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col>
                    <b-col>
                      <b-form-group id="input-group-name">
                        <label for="input-name">Loại tài khoản:</label>
                        <b-form-select
                          id="select-store"
                          required
                          v-model="selectedType"
                          aria-describedby="input-store-live-feedback"
                          :options="typeOptions"
                          size="sm"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >Chọn tài khoản kế toán</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group id="input-group-name">
                        <label for="input-name">Cửa hàng:</label>
                        <vue-autosuggest
                          class="border-radius-none"
                          aria-describedby="input-store-live-feedback"
                          :suggestions="filteredOptionsStore"
                          @selected="onSelectedStore"
                          :limit="10"
                          @input="onInputChangeStore"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Chọn cửa hàng',
                            style: 'border-radius:0px!important',
                          }"
                          v-model="searchStore"
                        >
                          <div
                            slot-scope="{ suggestion }"
                            style="display: flex; align-items: center"
                          >
                            <div style="{ display: 'flex', color: 'navyblue'}">
                              {{ suggestion.item.suggestionName }}
                            </div>
                          </div></vue-autosuggest
                        >
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group id="input-group-code">
                        <label for="input-code">Tài khoản cha:</label>
                        <vue-autosuggest
                          v-model="selectedParent"
                          :suggestions="filteredOptions"
                          @selected="onSelected"
                          :limit="10"
                          @input="onInputChange"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Chọn sản phẩm cha',
                          }"
                          :should-render-suggestions="
                            (size, loading) =>
                              size >= 0 && !loading && selectedParent !== ''
                          "
                        ></vue-autosuggest>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group id="input-group-code">
                        <label for="input-code">Mã tài khoản:</label>
                        <b-form-input
                          size="sm"
                          id="input-code"
                          v-model="code"
                          placeholder="Nhập mã"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group id="input-group-code">
                        <label for="input-code">Tên tài khoản:</label>
                        <b-form-input
                          size="sm"
                          id="input-code"
                          v-model="name"
                          placeholder="Nhập tên"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col></b-col>
                    <b-col>
                      <b-form-group id="input-group-name">
                        <label for="input-name">Trạng thái:</label>
                        <b-form-select
                          v-model="selectedStatus"
                          :options="statusOptions"
                          aria-describedby="input-active-live-feedback"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-col>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      type="submit"
                      @click="onSubmit"
                      >Lưu</b-button
                    >
                    <!-- </b-col> -->
                    <!-- <b-col lg="2" class="pb-2"> -->
                    <router-link to="/accounting/accounts" tag="button">
                      <b-button
                        style="margin-left: 10px; font-weight: 600; width: 70px"
                        variant="secondary"
                        size="sm"
                        >Hủy</b-button
                      >
                    </router-link>
                    <!-- </b-col> -->
                  </b-col>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { removeAccents } from './../../../utils/common';
import ApiService from '@/core/services/api.service';
import { getToastInstance } from '@/utils/toastHelper';

export default {
  data() {
    return {
      listAccSearch: [],
      selectedParent: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      listStore: [],
      selectedStore: '',
      parentId: '',
      code: '',
      name: '',
      selectedStatus: 1,
      statusOptions: [
        { value: '1', text: 'Kích hoạt' },
        { value: '2', text: 'Không kích hoạt' },
      ],
      selectedType: 1,
      typeOptions: [
        { value: '1', text: 'Tài khoản tiền mặt' },
        { value: '2', text: 'Tài khoản ngân hàng' },
        { value: '3', text: 'Tài khoản quẹt thẻ' },
        { value: '4', text: 'Tài khoản trả góp' },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      filteredOptionsStore: [],
      searchStore: '',
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.fetchStore();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kế toán', route: '/accountants' },
      { title: 'Cập nhật tài khoản kế toán', route: '/accountants' },
    ]);
  },
  computed: {
    selectedClassParent() {
      const acc = this.listAccSearch.find(
        (p) => p.name === this.selectedParent
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
  },
  created() {
    this.searchParent();
    this.getAccountantById();
  },
  methods: {
    ...getToastInstance(),
    getAccountantById: async function () {
      await ApiService.setHeader();
      await ApiService.get('accountants/' + this.id).then((res) => {
        this.selectedParent = res.data.data.parentName;
        this.selectedClassParent = res.data.data.parentId;
        this.selectedType = res.data.data.type;
        this.selectedStore = res.data.data.storeId;
        this.code = res.data.data.code;
        this.name = res.data.data.name;
        this.selectedStatus = res.data.data.status;
        this.searchStore = res.data.data.storeName;
      });
    },
    onInputChange(text) {
      this.selectedParent = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelected(option) {
      this.parentId = option.item;
      this.selectedParent = option.item;
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSubmit: async function () {
      if (this.searchStore.trim() === '') {
        this.selectedStore = null;
      }
      let params = {
        id: this.id,
        storeId: this.selectedStore,
        type: this.selectedType,
        parentId: this.selectedClassParent,
        code: this.code,
        name: this.name,
        status: this.selectedStatus,
      };
      ApiService.put('accountants', params)
        .then(response => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            this.$router.push({
              name: 'list-accountant',
              params: { createAccountantSuccess: true }
            });
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch(err => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    fetchStore: async function () {
      ApiService.setHeader();
      ApiService.get(
        `stores?name=${this.search || ''}&limit=1000&page=${this.page || 1}`
      ).then((response) => {
        response.data.data.list_store.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.optionsStore[0].data.push(store);
        });
        let tmp = [{ data: this.optionsStore[0].data }];
        this.filteredOptionsStore = tmp;
      });
    },
    searchParent: async function () {
      this.listAccSearch = [];
      ApiService.setHeader();
      ApiService.query('accountants/getAll').then((response) => {
        this.options[0].data = [];
        response.data.data.forEach((item) => {
          let acc = {
            id: item.id,
            name: item.name,
            code: item.code,
          };
          this.listAccSearch.push(acc);
          this.options[0].data.push(acc.name);
        });
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedStore = option.item.id;
    },
    onInputChangeStore(text) {
      this.searchStore = text;
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = [
        {
          data: filteredData,
        },
      ];
    },
  },
};
</script>

<style>
/* .form-group label {
  font-weight: 600;
} */

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
