var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('KTCodePreview',{attrs:{"title":'Thông tin tài khoản kế toán'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('b-form',{ref:"form",staticClass:"row",attrs:{"lazy-validation":""}},[_c('b-container',{staticClass:"bv-example-row"},[_c('b-row',[_c('b-col',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-name"}},[_c('label',{attrs:{"for":"input-name"}},[_vm._v("Loại tài khoản:")]),_c('b-form-select',{attrs:{"id":"select-store","required":"","aria-describedby":"input-store-live-feedback","options":_vm.typeOptions,"size":"sm"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Chọn tài khoản kế toán")])]},proxy:true}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-name"}},[_c('label',{attrs:{"for":"input-name"}},[_vm._v("Cửa hàng:")]),_c('vue-autosuggest',{staticClass:"border-radius-none",attrs:{"aria-describedby":"input-store-live-feedback","suggestions":_vm.filteredOptionsStore,"limit":10,"input-props":{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn cửa hàng',
                          style: 'border-radius:0px!important',
                        }},on:{"selected":_vm.onSelectedStore,"input":_vm.onInputChangeStore},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(" "+_vm._s(suggestion.item.suggestionName)+" ")])])}}]),model:{value:(_vm.searchStore),callback:function ($$v) {_vm.searchStore=$$v},expression:"searchStore"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-code"}},[_c('label',{attrs:{"for":"input-code"}},[_vm._v("Tài khoản cha:")]),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"limit":10,"input-props":{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn sản phẩm cha',
                        },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading && _vm.selectedParent !== ''; }},on:{"selected":_vm.onSelected,"input":_vm.onInputChange},model:{value:(_vm.selectedParent),callback:function ($$v) {_vm.selectedParent=$$v},expression:"selectedParent"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-code"}},[_c('label',{attrs:{"for":"input-code"}},[_vm._v("Mã tài khoản:")]),_c('b-form-input',{attrs:{"size":"sm","id":"input-code","placeholder":"Nhập mã"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-code"}},[_c('label',{attrs:{"for":"input-code"}},[_vm._v("Tên tài khoản:")]),_c('b-form-input',{attrs:{"size":"sm","id":"input-code","placeholder":"Nhập tên"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('b-col'),_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-name"}},[_c('label',{attrs:{"for":"input-name"}},[_vm._v("Trạng thái:")]),_c('b-form-select',{attrs:{"options":_vm.statusOptions,"aria-describedby":"input-active-live-feedback","size":"sm"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1)],1),_c('b-col'),_c('b-col'),_c('b-col')],1)],1)],1)]},proxy:true},{key:"foot",fn:function(){return [_c('b-container',{staticClass:"bv-example-row"},[_c('b-row',[_c('b-col',[_c('b-col',[_c('b-button',{staticStyle:{"fontweight":"600","width":"70px"},attrs:{"variant":"primary","size":"sm","type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v("Lưu")]),_c('router-link',{attrs:{"to":"/accounting/accounts","tag":"button"}},[_c('b-button',{staticStyle:{"margin-left":"10px","font-weight":"600","width":"70px"},attrs:{"variant":"secondary","size":"sm"}},[_vm._v("Hủy")])],1)],1)],1),_c('b-col'),_c('b-col'),_c('b-col')],1)],1)]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }